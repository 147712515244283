import React, { useEffect } from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";

const zendeskKey = "68074cdc-b4d8-47b9-b482-aaf293f854e1";

const ZendeskConfig = () => {
  useEffect(() => {
    const settings = {
      color: {
        theme: "#000",
      },
      launcher: {
        chatLabel: {
          "en-US": "Need Help",
        },
      },
      contactForm: {
        fields: [
          {
            id: "description",
            prefill: { "*": "My pre-filled description" },
          },
        ],
      },
    };

    const updateSettings = async () => {
      try {
        // Hide the widget initially
        await ZendeskAPI("webWidget", "hide");
        // Apply consistent settings
        await ZendeskAPI("webWidget", "updateSettings", settings);
      } catch (error) {
        console.error("Error updating Zendesk settings:", error);
      }
    };

    updateSettings();
  }, []);

  return (
    <Zendesk
      defer
      zendeskKey={zendeskKey}
      onLoaded={() => console.log("Zendesk Widget loaded")}
    />
  );
};

export default ZendeskConfig;
